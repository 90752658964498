var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        { ref: "xTable", attrs: { border: "", data: _vm.comments } },
        [
          _c("vxe-table-column", {
            attrs: {
              field: "commentName",
              title: "评论人姓名",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "commentPosName",
              title: "评论人职位",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "commentOrgName",
              title: "评论人组织",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "commentText",
              title: "评论内容",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "commentTime",
              title: "评论时间",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "commentNumLikes",
              title: "点赞数",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "visibleState",
              title: "是否可见",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: {
                        disabled: _vm.disabled,
                        "active-value": "1",
                        "inactive-value": "0",
                      },
                      on: {
                        change: function (val) {
                          return _vm.changeSwitch(val, row.id)
                        },
                      },
                      model: {
                        value: row.visibleState,
                        callback: function ($$v) {
                          _vm.$set(row, "visibleState", $$v)
                        },
                        expression: "row.visibleState",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-pageination" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.tablePage.currentPage,
              "page-sizes": [5, 10, 15, 20, 50, 100, 150, 200],
              "page-size": _vm.tablePage.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tablePage.totalResult,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }