<template>
  <div>
    <vxe-table border
               ref="xTable"
               :data="comments">
        <vxe-table-column field="commentName" title="评论人姓名" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column field="commentPosName" title="评论人职位" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column field="commentOrgName" title="评论人组织" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column field="commentText" title="评论内容" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column field="commentTime" title="评论时间" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column field="commentNumLikes" title="点赞数" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column field="visibleState" title="是否可见" min-width="100">
          <template v-slot="{row}">
            <el-switch v-model="row.visibleState" :disabled="disabled" active-value="1" inactive-value="0" @change="(val)=>changeSwitch(val, row.id)"></el-switch>
          </template>
        </vxe-table-column>
    </vxe-table>
    <div class="page-pageination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tablePage.currentPage"
        :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
        :page-size="tablePage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tablePage.totalResult"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.tablePage.totalResult = val.count || 0;
          this.comments = val.data || [];
        }
      },
    },
  },
  data() {
    return {
      comments: [], // 评论列表
      tablePage: {
        currentPage: 1,
        pageSize: 15,
        totalResult: 0,
      },
    };
  },
  created() {
  },
  methods: {
    changeSwitch(val, id) {
      request.get('/mdm/mdmNoticeCommentController/updateNoticeVisible', { id }).then((res) => {
        if (res.success) {
          this.tablePage.currentPage = 1;
          const tablePage = {
            pageNum: this.tablePage.currentPage,
            pageSize: this.tablePage.pageSize,
          };
          this.getComment(tablePage);
        }
      });
    },
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      const tablePage = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      };
      this.getComment(tablePage);
    },
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      const tablePage = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      };
      this.getComment(tablePage);
    },
    // 获取评论列表
    getComment(tablePage) {
      const param = {
        noticeId: this.id,
        ...tablePage,
      };
      request.post('/mdm/mdmNoticeCommentController/findCommentList', param).then((res) => {
        if (res.success) {
          const { result } = res;
          this.comments = result.data;
        }
      });
    },
  },
};
</script>
<style  lang="less" scoped>
/deep/ .page-pageination {
  text-align: right;
  padding-top: 10px;
  .el-input {
    display: inline-block !important;
  }
}
</style>
