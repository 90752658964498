<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import NoticeReceive from './components/notice_receive.vue';
import Comment from './components/comment.vue';

formCreate.component('NoticeReceive', NoticeReceive);
formCreate.component('Comment', Comment);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const that = this;
    const rule = await this.getFormRule('sfa_task_noticeManage_add');
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'noticeFileReqVos') {
        v.props = {
          ...v.props,
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: '只能上传pdf/word/excel/ppt/png/jpg文件',
          accept: '.pdf,.doc,.xls,.ppt,.png,.jpg,.jpeg,.xlsx,.docx,.pptx',
        };
      } else if (v.field === 'noticeReceive') {
        v.props = {
          ...v.props,
          disabled: this.formConfig.disabled,
        };
      } else if (v.field === 'comment') {
        v.props = {
          ...v.props,
          id: this.formConfig.id || '',
        };
      }
      if (this.formConfig.type === 'view') {
        v.props = {
          ...v.props,
          disabled: true,
        };
        this.hiddenFields(false, ['comment']);
        this.buttons.submit = false;
      } else {
        this.hiddenFields(true, ['comment']);
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      this.getDetail();
    }
    if (this.formConfig.type === 'view') {
      this.getComment({ pageNum: 1, pageSize: 15 });
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      request.get('/mdm/mdmNoticeController/queryNoticeDetail', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          const noticeReceive = {
            orgList: [],
            roleList: [],
          };
          result.noticeReceivingRespVos.map((v) => {
            const item = v;
            if (v.receivingType === '30') {
              noticeReceive.roleList.push({
                name: item.receivingName,
                code: item.receivingCode,
                receivingType: item.receivingType,
              });
            } else {
              noticeReceive.orgList.push({
                name: item.receivingName,
                code: item.receivingCode,
                receivingType: '10',
              });
            }
            return item;
          });
          result.noticeFileReqVos = result.noticeFileRespVos;
          result.noticeReceive = noticeReceive;
          this.setValue(result);
          this.row = res.result;
        }
      });
    },
    // 获取评论列表
    getComment(tablePage) {
      const param = {
        noticeId: this.formConfig.id,
        ...tablePage,
      };
      request.post('/mdm/mdmNoticeCommentController/findCommentList', param).then((res) => {
        if (res.success) {
          const { result } = res;
          const comment = result;
          this.setValue({
            comment,
          });
        }
      });
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (!formData) return false;
      const param = JSON.parse(JSON.stringify(formData));
      param.noticeReceivingReqVos = [];
      const list = param.noticeReceive.orgList.concat(param.noticeReceive.roleList);
      list.map((item) => {
        param.noticeReceivingReqVos.push({
          receivingCode: item.code,
          receivingName: item.name,
          receivingType: item.receivingType,
        });
        return item;
      });
      delete param.noticeReceive;
      if (param.noticeFileReqVos) {
        param.noticeFileReqVos.map((v) => {
          const item = v;
          if (!item.realPath) {
            item.realPath = item.urlPathPrefix;
          }
          return item;
        });
      }
      if (formData.beginDate.split(' ').length === 1) {
        param.beginDate = `${formData.beginDate} 00:00:00`;
      }
      if (formData.endDate.split(' ').length === 1) {
        param.endDate = `${formData.endDate} 23:59:59`;
      }
      if (new Date(param.beginDate).getTime() > new Date(param.endDate).getTime()) {
        this.$message.error('有效结束时间不能小于有效开始时间');
        return false;
      }
      if (param) {
        let url = '/mdm/mdmNoticeController/save';
        let params = { ...param };
        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmNoticeController/update';
          param.id = this.row.id;
          param.ids = [this.row.id];
          param.noticeCode = this.row.noticeCode;
          params = { ...param };
        }
        // params.visibleState = '1';
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
