<template>
  <div>
    <div>适用组织</div>
    <el-row class="margin-bottom-10" v-if="!disabled">
      <el-button type="primary" @click="showModal('org')">添加</el-button>
      <el-button plain @click="delTable('org')">删除</el-button>
    </el-row>
    <vxe-table :data="orgList" @checkbox-all="({records}) => selectEvent('selectList',records)" @checkbox-change="({records}) => selectEvent('selectList',records)">
      <vxe-table-column type="checkbox" width="50" v-if="!disabled"></vxe-table-column>
      <vxe-table-column title="组织编码" field="code" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="组织名称" field="name" min-width="100" show-overflow></vxe-table-column>
    </vxe-table>
    <div>适用角色</div>
    <el-row class="margin-bottom-10" v-if="!disabled">
      <el-button type="primary" @click="showModal('role')">添加</el-button>
      <el-button plain @click="delTable('role')">删除</el-button>
    </el-row>
    <vxe-table :data="roleList" @checkbox-all="({records}) => selectEvent('roleSelectList', records)" @checkbox-change="({records}) => selectEvent('roleSelectList', records)">
      <vxe-table-column type="checkbox" width="50" v-if="!disabled"></vxe-table-column>
      <vxe-table-column title="角色编码" field="code" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="角色名称" field="name" min-width="100" show-overflow></vxe-table-column>
    </vxe-table>

    <!-- 选择组织弹窗 -->
    <Modal :modalConfig="modalConfig" @onClose="closeModal('org')">
      <vxe-table border
                row-id="code"
                ref="xTable"
                class="vxeTable"
                @checkbox-change="dialog_checkboxChange"
                @checkbox-all="dialog_checkAll"
                :tree-config="{ children: 'children' }"
                :data="modalConfig.tableData"
                :checkbox-config="{checkRowKeys:modalConfig.checkRowKeys, checkStrictly: true, checkMethod:dialog_isCanCheck}"
                :expand-config="{expandRowKeys:modalConfig.checkRowKeys}">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column tree-node field="name" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="closeModal('org')" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
    <!-- 选择角色弹窗 -->
    <Modal :modalConfig="roleModalConfig" @onClose="closeModal('role')">
      <el-row :gutter="24" class="margin-bottom-10">
        <el-col :span="6">
          <el-input v-model="params.roleCode" placeholder="请输入角色编码"></el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="params.roleName" placeholder="请输入角色名称"></el-input>
        </el-col>
        <el-col :span="6" :offset="6">
          <el-button type="primary" @click="getRoleList" icon="el-icon-search">查询</el-button>
          <el-button type="default" @click="resetQuery" icon="el-icon-refresh">重置</el-button>
        </el-col>
      </el-row>
      <vxe-table border
                row-id="code"
                class="vxeTable"
                @checkbox-change="dialog_selectRole"
                @checkbox-all="dialog_selectRole"
                :data="roleModalConfig.tableData"
                :checkbox-config="{checkMethod: dialog_isCanCheck}"
                :loading="roleModalConfig.tableLoading">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column field="code" title="角色编码"></vxe-table-column>
          <vxe-table-column field="name" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="page-pageination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.pageNum"
          :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="roleSubmit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="closeModal('role')" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';
import * as utils from '../../../../../../../utils/index';

export default {
  components: {
    Modal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.orgList = val.orgList || [];
          this.roleList = val.roleList || [];
        }
      },
    },
    orgList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.checkRowKeys = [];
        if (val && Array.isArray(val)) {
          val.map((item) => {
            this.modalConfig.checkRowKeys.push(item.code);
            return item;
          });
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        title: '选择组织',
        width: '800px',
        visible: false,
        type: 'Modal',
        tableData: [], // 列表数据
        checkRowKeys: [], // 默认选中的
        selection: [], // 选中的
      }, // 选择组织弹窗
      orgList: [], // 组织列表
      selectList: [], // 选择的列表
      roleModalConfig: {
        title: '选择角色',
        width: '800px',
        visible: false,
        type: 'Modal',
        tableData: [], // 列表数据
        checkRowKeys: [], // 默认选中的
        selection: [], // 选中的
        tableLoading: false,
      }, // 选择角色弹窗
      roleList: [], // 组织列表
      roleSelectList: [], // 选择的列表
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      params: { // 查询角色参数
        pageNum: 1,
        pageSize: 15,
        roleCode: '', // 角色编码
        roleName: '', // 角色名称
      },
    };
  },
  created() {
    this.getOrgList();
  },
  methods: {
    /** @desc 顶部添加按钮 */
    showModal(type) {
      if (type === 'org') {
        this.modalConfig.visible = true;
      } else if (type === 'role') {
        this.roleModalConfig.visible = true;
        this.page.pageNum = 1;
        this.roleModalConfig.tableData = [];
        this.getRoleList();
      }
    },
    /** @desc 关闭选择组织弹窗 */
    closeModal(type) {
      if (type === 'org') {
        this.modalConfig.visible = false;
      } else if (type === 'role') {
        this.roleModalConfig.visible = false;
      }
    },
    /** @desc 获取角色选择列表 */
    getRoleList() {
      this.roleModalConfig.tableLoading = true;
      request.post('/mdm/mdmRoleController/pageList', this.params).then((res) => {
        this.roleModalConfig.tableLoading = false;
        if (!res.success) return false;
        const list = res.result.data || [];
        this.page.total = res.result.count || 0;
        this.roleModalConfig.tableData = list.map((v) => ({ ...v, code: v.roleCode, name: v.roleName }));
      });
    },
    /** @desc size变化 */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.params.pageSize = val;
      this.getRoleList();
    },
    /** @desc page变化 */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.params.pageNum = val;
      this.getRoleList();
    },
    dialog_selectRole({ records }) {
      this.roleModalConfig.selection = records;
    },
    /** @desc 角色选择弹窗确定按钮 */
    roleSubmit() {
      if (this.roleModalConfig.selection.length === 0) return this.$message.warning('请至少选择一条角色数据！');
      const selection = this.roleModalConfig.selection.map((v) => ({ ...v, receivingType: '30' }));
      this.roleList = utils.uniqueArr(this.roleList, selection, 'code');
      this.roleModalConfig.checkRowKeys = [];
      this.roleModalConfig.selection = [];
      this.roleModalConfig.visible = false;
      this.emitChange();
    },
    /** @desc 获取组织选择列表 */
    getOrgList() {
      request.post('/mdm/baseTreeController/orgTree').then((res) => {
        if (!res.success) return false;
        this.modalConfig.tableData = res.result || [];
      });
    },
    /** @desc 重置查询 */
    resetQuery() {
      this.params = {
        pageNum: 1,
        pageSize: 15,
        roleCode: '', // 角色编码
        roleName: '', // 角色名称
      };
      this.getRoleList();
    },
    /** @desc 单选框改变事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_checkboxChange({ records, checked, row }) {
      this.modalConfig.selection = [...records];
      if (checked) {
        const transver = (value) => {
          if (value.children) {
            this.$refs.xTable.setCheckboxRow(value, true);
            value.children.map((a) => {
              const pItem = a;
              this.modalConfig.selection.push(pItem);
              this.$refs.xTable.setCheckboxRow(pItem, true);
              transver(pItem);
              return a;
            });
          }
        };
        transver(row);
      } else {
        this.$refs.xTable.setCheckboxRow(row, false);
      }
    },
    /** @desc 全选事件* @param{records} 所有选中的行，扁平化的列表 */
    dialog_checkAll({ records }) {
      this.modalConfig.selection = [...records];
    },
    /** @desc 不允许选中的事件 */
    dialog_isCanCheck({ row }) {
      return !row.disabled;
    },
    /** @desc 按钮确认事件 */
    submit() {
      if (this.modalConfig.selection.length === 0) return this.$message.warning('请至少选择一条组织数据！');
      const selection = this.modalConfig.selection.map((v) => ({ ...v, receivingType: '10' }));
      this.orgList = selection;
      this.modalConfig.checkRowKeys = [];
      this.modalConfig.selection = [];
      this.modalConfig.visible = false;
      this.emitChange();
    },
    /** @desc 选择列表事件 */
    selectEvent(key, records) {
      this.$set(this, key, records);
    },
    /** @desc 顶部删除控件按钮 */
    delTable(type) {
      if (type === 'org') {
        // 删除选中的数据
        if (this.selectList && this.selectList.length > 0) {
          this.selectList.map((item) => {
            const index = this.orgList.findIndex((val) => val.code === item.code);
            if (index > -1) {
              this.orgList.splice(index, 1);
            }
            return item;
          });
          this.selectList = [];
          this.modalConfig.checkRowKeys = [];
        } else {
          this.$message.warning('请选择数据');
        }
      } else if (type === 'role') {
        // 删除选中的数据
        if (this.roleSelectList && this.roleSelectList.length > 0) {
          this.roleSelectList.map((item) => {
            const index = this.roleList.findIndex((val) => val.code === item.code);
            if (index > -1) {
              this.roleList.splice(index, 1);
            }
            return item;
          });
          this.roleSelectList = [];
          this.roleModalConfig.checkRowKeys = [];
        } else {
          this.$message.warning('请选择数据');
        }
      }
      this.$forceUpdate();
      this.emitChange();
    },
    emitChange() {
      let value = null;
      if (this.orgList.length > 0 || this.roleList.length > 0) {
        value = {
          orgList: this.orgList || [],
          roleList: this.roleList || [],
        };
      }
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .page-pageination {
    text-align: right;
    padding-top: 10px;
    .el-input {
      display: inline-block !important;
    }
  }
  .margin-bottom-10{
    margin-bottom: 10px;
  }
</style>
